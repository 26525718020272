import { useEffect, useState } from "react";
import { useIsMobile } from "ui/lib/use-breakpoint";

export const useShortDeeplink = (deepLink) => {
  const [shortUrl, setShortUrl] = useState();
  const [error, setError] = useState(false);
  const isMobile = useIsMobile();

  useEffect(() => {
    if (isMobile) return;
    if (!deepLink) return;
    if (!!shortUrl) return;

    fetch("/api/url-shortener", {
      method: "POST",
      body: JSON.stringify({
        url: deepLink,
      }),
    })
      .then((response) => response.json())
      .then(({ key }) => {
        if (!key) {
          throw new Error("missing shortened url key");
        }
        const url = new URL(
          "/quiz/qr-deeplink/redirect",
          window.location.origin
        );
        url.searchParams.set("key", key);

        setShortUrl(url.href);
        setError(false);
      })
      .catch((error) => {
        console.log({ error });
        setError(true);
      });
  }, [deepLink, shortUrl, isMobile]);

  return { deepLink, shortUrl, error };
};
