import Cookies from "js-cookie";
import { useRouter } from "next/router";
import React, { useEffect, useRef } from "react";
import { Toaster } from "react-hot-toast";
import { captureEvent } from "ui/lib/captureEvent";
import AIChatBot from "web/components/ai-chat-bot";
import { Footer } from "web/components/footer";
import { HeadContent } from "web/components/head";
import { HomeComponent } from "web/components/home";
import OptimizeLayout from "web/components/layout";
import { useModals } from "web/components/modals/container";
import { DesktopQRModal } from "web/components/modals/desktop-qr-modal";
import Nav from "web/components/nav";
import Notice from "web/components/notice/home";
import { ExperimentProvider } from "web/state-containers/experiment";
import { isDev } from "../helpers/misc";

const abTest = "ab-test-home-hero_12_24";

const Content = ({ abTestGroup }) => {
  const { query, isReady } = useRouter();
  const { toggleShowQRModal } = useModals();

  useEffect(() => {
    if (!isReady) return;
    if (!query.showAppDownloadQR) return;

    toggleShowQRModal();
  }, [query, isReady, toggleShowQRModal]);

  return (
    <>
      <Notice abTest={abTest} abTestGroup={abTestGroup} />
      <Nav showWWOD />
      <div className="h-full max-w-full overflow-hidden">
        <HomeComponent abTest={abTest} abTestGroup={abTestGroup} />
        <Footer />
      </div>
    </>
  );
};

const defaultLink = `https://ladder.page.link/?ibi=com.ladder.bootcamp&isi=1502936453&ius=ladderteams&link=https://${
  isDev ? "dev." : ""
}joinladder.com`;

export default function Home() {
  const effectCalled = useRef(false);
  const group = Cookies.get(abTest);

  useEffect(() => {
    if (effectCalled.current) return;

    if (!!group) {
      effectCalled.current = true;
      captureEvent({
        action: "Web Experiment Exposure",
        group: group,
        name: abTest,
      });
      captureEvent({
        action: "Web Experiment Entered",
        group: group,
        name: abTest,
      });
    }
  }, [group]);

  return (
    <>
      <HeadContent title="Ladder | Your Daily Workout Plan" />
      <Toaster
        position="top-center"
        toastOptions={{
          // Define default options
          className: "",
          duration: 5000,
        }}
      />
      <ExperimentProvider>
        <DesktopQRModal
          title="Download the app!"
          description="Scan the QR code start your workout"
          deepLink={defaultLink}
        />
        <Content abTestGroup={group} />
        <AIChatBot />
      </ExperimentProvider>
    </>
  );
}

Home.Layout = OptimizeLayout;
