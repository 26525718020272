import { Dialog, DialogContent } from "ui/components/primitives/dialog";
import { QRCode } from "web/components/qr-code/QRCode";
import { useShortDeeplink } from "../quiz-consumer/hooks/useShortDeeplink";
import { useHandleClickCTA } from "../workout/misc";
import { useModals } from "./container";

export const DesktopQRModal = ({
  launchProgramID = "3a3ee0d8-e817-4c06-8620-351a27da67f1",
  title = "GET THE LADDER APP",
  description = "Scan to download the Ladder iOS app!",
}) => {
  const { value, showQRModal, toggleShowQRModal } = useModals();
  const { deepLink } = useHandleClickCTA(launchProgramID, "GET THE APP");

  const { shortUrl, error } = useShortDeeplink(showQRModal && deepLink);

  return (
    <Dialog open={showQRModal} onOpenChange={toggleShowQRModal}>
      <DialogContent
        overlay
        className=" px-4 pt-5 pb-6 overflow-hidden text-left align-bottom transition-all transform border shadow-xl bg-dark-slanted-gradient rounded-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:pt-6 sm:px-12 sm:pb-12"
      >
        <h1 className="py-6 mb-2 text-4xl tracking-wide text-center text-foreground uppercase 2xl:text-5xl font-display">
          {title}
        </h1>
        <div
          className="flex items-center justify-center p-6 mx-auto transition-transform duration-150 ease-in-out bg-white border border-black shadow-2xl rounded-3xl"
          style={{ width: 330, height: 330 }}
        >
          {error ? (
            "Something went wrong. Please refresh and try again."
          ) : value || shortUrl ? (
            <QRCode size={280} logoSize={0} value={value || shortUrl} />
          ) : (
            "Loading..."
          )}
        </div>
        <div className="pt-3 pb-3 space-x-2 text-xl font-bold text-center 2xl:text-2xl text-primary">
          {description}
        </div>
      </DialogContent>
    </Dialog>
  );
};
