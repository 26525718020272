"use client";
import Script from "next/script";
import { renderSegmentSnippet } from "ui/lib/renderSegmentSnippet";
import BaseLayout from "web/components/base-layout";
import { HeadContent } from "web/components/head";
import { Page } from "web/components/page";

const Wrapper = ({ children }) => {
  return (
    <>
      <HeadContent />
      {/* Inject the Segment snippet into the <head> of the document  */}
      {process.env.NEXT_PUBLIC_LADDER_QUIZ_SEGMENT_WRITE_KEY ? (
        <Script
          id="segment"
          dangerouslySetInnerHTML={{
            __html: renderSegmentSnippet(
              process.env.NEXT_PUBLIC_LADDER_QUIZ_SEGMENT_WRITE_KEY
            ),
          }}
        />
      ) : null}
      {children}
    </>
  );
};

const Layout = ({ children, ...props }) => {
  return (
    <Wrapper>
      <BaseLayout {...props}>
        <Page>{children}</Page>
      </BaseLayout>
    </Wrapper>
  );
};

export default Layout;
