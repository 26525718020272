import * as React from "react";
const desktop = "(min-width: 1024px)";
export function useMediaQuery(query: string) {
  const [value, setValue] = React.useState(() => {
    if (typeof window !== "undefined") {
      const result = window.matchMedia(query);
      return result.matches;
    }
    return false;
  });
  React.useEffect(() => {
    function onChange(event: MediaQueryListEvent) {
      setValue(event.matches);
    }
    const result = matchMedia(query);
    result.addEventListener("change", onChange);
    setValue(result.matches);
    return () => result.removeEventListener("change", onChange);
  }, [query]);
  return value;
}
export const useIsMobile = () => {
  const value = useMediaQuery(desktop);
  return !value;
};
export const useIsDesktop = () => {
  const value = useMediaQuery(desktop);
  return value;
};