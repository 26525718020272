import { motion } from "motion/react";
import { cn } from "ui/lib/utils";
import { useChatBotUIConfig } from "./ChatBotUIProvider";
import { useAtomValue } from "jotai";
import React, { useEffect, useState } from "react";
import { DialogTrigger } from "ui/components/primitives/dialog";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "ui/components/primitives/tooltip";
import { useIsScrolling } from "ui/hooks/misc";
import { pluralize } from "ui/lib/helpers";
import { TypingIndicator } from "../typing-indicator";
import { streamingTextAtom, useChatBotAIProps } from "./ChatBotAIPropsProvider";
export const ChatbotFloatingCTA = React.memo(() => {
  const streamingText = useAtomValue(streamingTextAtom);
  const {
    notifications
  } = useChatBotUIConfig();
  const {
    messages,
    isLoading
  } = useChatBotAIProps();
  const [showToolTip, setShowToolTip] = useState(false);
  const isScrolling = useIsScrolling();
  useEffect(() => {
    if (isScrolling) setShowToolTip(false);
    const t = setTimeout(() => setShowToolTip(true), 2500);
    return () => clearTimeout(t);
  }, [isScrolling]);
  return <TooltipProvider>
      <Tooltip open={showToolTip} onOpenChange={setShowToolTip}>
        <TooltipTrigger asChild>
          <DialogTrigger asChild>
            <div className="fixed z-50 bottom-4 right-4">
              <motion.button initial={{
              y: 50,
              x: -50,
              opacity: 0
            }} animate={{
              y: 0,
              x: 0,
              opacity: 1
            }} transition={{
              delay: 2.5
            }} className="group flex items-center justify-center relative rounded-full bg-neutral-600 text-3xl p-3 w-16 h-16 shadow-button">
                {notifications ? <span className="text-black bg-primary p-1 w-5 h-5 center text-sm rounded-full absolute top-0 left-0">
                    {notifications}
                  </span> : null}
                <span className={cn("block transition-all scale-100 group-hover:scale-125 group-hover:wave", {
                wave: notifications || showToolTip
              })}>
                  👋
                </span>
              </motion.button>
            </div>
          </DialogTrigger>
        </TooltipTrigger>
        <TooltipContent>
          <p>
            {isLoading || !!streamingText ? <TypingIndicator /> : notifications && messages.length ? `${notifications} new ${pluralize(notifications, "message")}` : "Hi! How can I help you?"}
          </p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>;
});