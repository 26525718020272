import QR from "react-qr-code";
import React, { useMemo } from "react";
import ErrorBoundary from "ui/components/error-boundary";

export const QRCode = ({ value = "QR Code", size }) => {
  return (
    <ErrorBoundary>
      <QR value={value} size={size} />
    </ErrorBoundary>
  );
};
