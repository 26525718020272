import * as DialogPrimitive from "@radix-ui/react-dialog";
import { cn } from "ui/lib/utils";
import { X } from "lucide-react";
import React from "react";
import { Dialog, DialogFooter, DialogOverlay, DialogPortal, DialogTrigger, DialogContent as RadixDialogContent } from "ui/components/primitives/dialog";
import { ChatBotAIPropsProvider, ChatBotAIProviderConfigProps } from "./ChatBotAIPropsProvider";
import { ChatBotActionsProvider } from "./ChatBotActionsProvider";
import { ChatBotBody } from "./ChatBotBody";
import { ChatBotHeader } from "./ChatBotHeader";
import { ChatBotInput } from "./ChatBotInput";
import { ChatBotUIConfigProvider, ChatBotUIProviderConfigProps, useChatBotUIConfig } from "./ChatBotUIProvider";
import { dialogContainerVariants, useAdjustContentHeight } from "./helpers";
interface DialogContentProps extends React.ComponentPropsWithoutRef<typeof RadixDialogContent> {
  className?: string;
  children: React.ReactNode;
}
export const DialogContent = React.forwardRef<HTMLDivElement, DialogContentProps>(({
  className,
  children,
  ...props
}, ref) => <DialogPortal>
    <DialogPrimitive.Content ref={ref} className={cn("animate-in data-[state=open]:fade-in-90 data-[state=open]:slide-in-from-bottom-10 fixed z-50 flex flex-col w-full gap-4 p-6", "dark:bg-background", className)} {...props}>
      {children}
      <DialogPrimitive.Close className="absolute rounded-full top-2 right-4 shadow-button opacity-70 transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-neutral-100 dark:focus:ring-slate-400 dark:focus:ring-offset-slate-900 dark:bg-secondary p-2">
        <X className="h-4 w-4" />
        <span className="sr-only">Close</span>
      </DialogPrimitive.Close>
    </DialogPrimitive.Content>
  </DialogPortal>);
interface ChatBotUIDialogComponentsProps {
  children: React.ReactNode;
  onOpenChange?: (value: boolean) => void;
}
const ChatBotUIDialogComponents = ({
  children,
  onOpenChange
}: ChatBotUIDialogComponentsProps) => {
  const {
    open,
    setOpen,
    variant,
    overlay
  } = useChatBotUIConfig();
  const maxHeight = useAdjustContentHeight();
  return <Dialog open={open} onOpenChange={value => {
    setOpen(value);
    onOpenChange?.(value);
  }} data-sentry-element="Dialog" data-sentry-component="ChatBotUIDialogComponents" data-sentry-source-file="ChatBotUIDialogComponents.tsx">
      <DialogTrigger asChild data-sentry-element="DialogTrigger" data-sentry-source-file="ChatBotUIDialogComponents.tsx">{children}</DialogTrigger>
      <DialogPrimitive.Portal data-sentry-element="unknown" data-sentry-source-file="ChatBotUIDialogComponents.tsx">
        {overlay ? <DialogOverlay /> : null}
        <div>
          <DialogContent style={{
          maxHeight
        }} className={dialogContainerVariants({
          variant
        })} data-sentry-element="DialogContent" data-sentry-source-file="ChatBotUIDialogComponents.tsx">
            <ChatBotHeader data-sentry-element="ChatBotHeader" data-sentry-source-file="ChatBotUIDialogComponents.tsx" />
            <ChatBotBody data-sentry-element="ChatBotBody" data-sentry-source-file="ChatBotUIDialogComponents.tsx" />
            <DialogFooter className="relative flex-col sm:flex-col p-4" data-sentry-element="DialogFooter" data-sentry-source-file="ChatBotUIDialogComponents.tsx">
              <ChatBotInput data-sentry-element="ChatBotInput" data-sentry-source-file="ChatBotUIDialogComponents.tsx" />
              <div className="text-xs text-center text-muted-foreground pt-1">
                ArnieAI (Beta) may provide inaccurate information. Please
                contact support{" "}
                <a href="https://airtable.com/shrrrXxt1CKpOkSme" target="_blank" rel="noreferrer" className="text-royal-400">
                  here
                </a>{" "}
                for more help.
              </div>
            </DialogFooter>
          </DialogContent>
        </div>
      </DialogPrimitive.Portal>
    </Dialog>;
};
interface ChatBotUIDialogProps extends ChatBotUIProviderConfigProps {
  children: React.ReactNode;
  onOpenChange?: (value: boolean) => void;
  chatProps: ChatBotAIProviderConfigProps;
}
export const ChatBotUIDialog = ({
  children,
  onOpenChange,
  chatProps,
  ...props
}: ChatBotUIDialogProps) => {
  return <ChatBotAIPropsProvider initialState={{
    chatProps
  }} data-sentry-element="ChatBotAIPropsProvider" data-sentry-component="ChatBotUIDialog" data-sentry-source-file="ChatBotUIDialogComponents.tsx">
      <ChatBotUIConfigProvider initialState={props} data-sentry-element="ChatBotUIConfigProvider" data-sentry-source-file="ChatBotUIDialogComponents.tsx">
        <ChatBotActionsProvider data-sentry-element="ChatBotActionsProvider" data-sentry-source-file="ChatBotUIDialogComponents.tsx">
          <ChatBotUIDialogComponents onOpenChange={onOpenChange} data-sentry-element="ChatBotUIDialogComponents" data-sentry-source-file="ChatBotUIDialogComponents.tsx">
            {children}
          </ChatBotUIDialogComponents>
        </ChatBotActionsProvider>
      </ChatBotUIConfigProvider>
    </ChatBotAIPropsProvider>;
};